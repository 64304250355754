.wrap-text {
    word-wrap: break-word !important;
    white-space: normal !important;
}

.customer-name-phone {
    max-width: 150px; /* Adjust as needed */
}

.customer-address {
    max-width: 200px; /* Adjust as needed */
}


