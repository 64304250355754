.panel-search {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .panel-body {
    overflow:auto;
}
  
  .datepicker-container {
    margin-right: 20px;
    margin-left: 5 px;
  }
  
  
  .dropdown-container {
    margin-right: 20px;
  }

  .dropdown-container label {
    margin-right: 2px;
  }

  .inputPanels {
    margin-left: 20px;
  }

  .inputPanels label {
    margin-bottom: 10px;
  }

  .inputPanels select {
    display: block;
  }

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 40%;  */
}

footer h4 {
  position: relative;
  display: inline;

}

.grid-container {
  display: grid;
  grid-template-columns: 30% 70%;
  /* Two columns, first is 30%, second is 70% */
  gap: 1px;
  /* Gap between columns, adjust as needed */
}

.parent-panel {
  border: 1px solid #000;
  /* Border style */
}

.panel1 {
  background-color: #ccc;
  /* Background color for panel 1 */
}

.panel2 {
  background-color: #eee;
  /* Background color for panel 2 */
  border-radius: 10px;
}