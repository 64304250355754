
  .button-group {
    display: flex;
    gap: 20px;
  }
  
  

  .compose_email_form{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .email_inputs{
    height: 40px;
    width: 100%;
    border: 1px solid #ced4da;
  }

  .email_inputs:focus{
    outline: none;
    border: 1px solid #007bff;
  }


  .email_text{
   
    border: 1px solid #ced4da;
  }

  .email_text:focus{
    outline: none;
    border: 1px solid #007bff;
  }
  .email_labels{
    font-size: 18px;

  }

  .send_email_btn{
    width: 200px;
    height: 40px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }


  .email_card{
    border: 1px solid #ced4da;
    margin: 5px 0px;
    border-radius: 5px;
    background-color: #fff;
  }

  .email_card_inner_container{
    display: flex;
    gap: 10px;
    padding: 10px;
  }


  .e_heading{
    font-size: 16px;
    font-weight: bold;
  }

  .email_card_inner_container > p{
    font-size: 12px;

  }



  .email_btn{
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 10px;
    height: 40px;
    width: 150px;
  }

  .email_chip{
    background-color: red;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    height: 30px;
   
}