body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.page_heading{
  font-size: 18px;
  color: #288a84;
  font-weight: 700;
  margin-top: 12px;
  text-align: center;
}

.filter_containers{
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
 
}

.custom_container{
  margin: 5px;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; 
  margin-bottom: 200px;
}

.pagination_text{
  font-size: 14px;
  color: #288a84;
  font-weight: 700;
 
}



