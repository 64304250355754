.report-viewer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.category-card {
  width: 300px;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.category-title {
  margin-top: 0;
}

.report-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report-card {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.report-card:hover {
  background-color: #f0f0f0;
}


.spinner-container {
  height: 15vh;
}

.spinner-overlay {
  position: absolute;
  margin-left: 43%;
  margin-top: 5%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;

  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.spinnerLogin {
  position: absolute;
  top: 75%;
  left: 50%;

  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.export-excel-button {
  background-color: #007bff;
  /* Blue color */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.export-excel-button:hover {
  background-color: #0056b3;
  /* Darker shade of blue on hover */
}



.low-cr-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-container {
  display: flex;
  align-items: center;
}

.select-container h4 {
  margin-right: 10px;
  /* Add some space between h4 and select */
}

.export-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.export-button:hover {
  background-color: #0056b3;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.custom-button-blue {
  color:    #ffff;
  font-size:   14px !important;
  font-weight: 500;
  background: #4a54ba;
}