/* Add a background to the report container */
.bluck-update-report-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* General styling for the date input fields */
.set-data {
  width: 250px;
}

/* Align filters (Start Date, End Date, Product) in a single row */
.bluck-update-report-filters {
  display: flex;
  justify-content: space-between; /* Distribute filters across the row */
  align-items: center;
  margin-bottom: 20px;
}

/* Style the filter labels */
.bluck-update-report-filters label {
  margin-right: 10px;
  font-size: 14px;
}

/* Product dropdown styling */
.filter-item {
  margin-left: 30px; /* Add some margin for better spacing */
  margin-top: 5px;
}

/* Style for the search button */
.bluck-update-search-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s;
}

/* Hover effect for the search button */
.bluck-update-search-button:hover {
  background-color: #357abd;
}

/* Table Styling */
.bluck-update-report-table {
  width: 100%;
  border-collapse: collapse;
}

.bluck-update-report-table thead th {
  background-color: #4a90e2;
  color: white;
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.bluck-update-report-table tbody td {
  padding: 8px;
  border: 1px solid #ddd;
  color: #333;
}

/* Add zebra striping for rows */
.bluck-update-report-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

/* Add spacing and border for the result section */
.result {
  margin-top: 20px;
  overflow-x: auto;
  height: 400px; /* You can adjust this height based on your preference */
}

/* Add the empty state style */
.bluck-update-empty-state {
  text-align: center;
  font-size: 16px;
  color: #777;
}

/* Style the export buttons */
.bluck-update-export-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.css-13cymwt-control{
  width:250px !important;
}
/* Hover effect for export buttons */
.bluck-update-export-button:hover {
  background-color: #357abd;
}
