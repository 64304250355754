.multi-select-dropdown {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  select {
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .chip {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 15px;
    z-index: 1;
  }
  
  .close-btn {
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .multi_select{
   border: 1px solid #ccc;
   height: 30px;

  }