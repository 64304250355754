/* .card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }
  
  .card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
  }
  
  .card-title {
    margin-bottom: 0;
  }
  
  .card-subtitle {
    margin-top: 0.5rem;
  }
  
  .card-body {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  
  }
  
  .card-body p strong {
    display: inline-block;
    
  }
.card_header{
  font-weight: bold;
  font-size: 18px;
} */


.c_card{
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.c_card_body{
  display: flex;
  padding: 20px;
  gap: 5px;
  justify-content: space-between;
  background-color: white;
  flex-wrap: wrap;
}
.card_hearder{
  font-weight: bold;
  font-size: 12px;
}

.card_value{
  font-size: 12px;
}

.flex_card_body{
  max-width: 200px;
}

.c_notes{
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  background-color: #d3d3d3;
  padding: 5px;
  margin: 0px 10px;

}

.product_chip{
  background-color: #84bf93;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  
}

.feedback_chip{
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #000;
}