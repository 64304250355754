.Toastify__toast {
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    color: #333333;
    font-family: Arial, sans-serif;
    font-size: 16px;
  }
  
  .Toastify__toast--success {
    border-left: 6px solid #4caf50; 
  }
  
  .Toastify__toast--error {
    border-left: 6px solid #f44336; 
  }
  
  .Toastify__toast--info {
    border-left: 6px solid #2196f3; 
  }
  
  .Toastify__toast-body {
    padding: 16px;
  }
  