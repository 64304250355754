 .modal-content {
    display: flex;
    flex-direction: column;
  }
  
 .panel-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px; 
  }
  

  .panel {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  .panel h2 {
    margin-top: 0;
  }
  
  .panel input,
  .panel select {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  

  .second-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    margin-top: 20px;
  }
  
  .percentage {
    
    font-size: 1rem; 
    color: #333; 
    margin-left: 4px; 
  } 
  

  .third-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    margin-top: 20px;
  }
  
  .panel input[type="text"][placeholder="GST"] {
    width: 20%; 
  }
  
  .validation-message {
    color: red; 
    font-size: 0.8rem; 
    margin-top: 2px; 
  }
  
  .image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-label {
    display: block;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .upload-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
  }
  
  .upload-placeholder span {
    font-size: 16px;
  }
  
  .error-bubble {
    position: absolute;
    background-color: #ffcccc;
    color: #ff0000;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .switch-label {
    display: flex;
    align-items: center;
    
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .toggle-switch input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-switch input[type="checkbox"] + label {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 24px;
    background-color: #ccc;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .toggle-switch input[type="checkbox"] + label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .toggle-switch input[type="checkbox"]:checked + label {
    background-color: #6ab04c; 
  }
  
  .toggle-switch input[type="checkbox"]:checked + label:before {
    transform: translateX(26px); 
  }

  .form-switch {
    padding-left: 2.5em;
}

.side-by-side {
  display: flex;
  flex-direction: row;
}
