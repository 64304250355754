.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .popup-inner{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 60vw;
    max-height: 80vh;
     overflow-y: auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
 */
 .popup-inner {
    background-color:white;
    padding: 67px;
    border-radius: 5px;
    margin-top:10px;
    width: 45%;
    height: 80%;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.popup-inner_small{
    background-color: white;
    padding: 20px;
    /* border-radius: 5px; */
    /* width: 40vw; */
    /* max-height: 80vh;
    overflow-y: auto; */
    /* box-shadow: 0 0 10px rgba(0,0,0,0.1); */
}
.menu_box{
    display: flex;
    gap: 20px;
   
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.c_btn{
    cursor: pointer;
    background-color: #172D88;
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    height: 40px;
    color: #f1f1f1 ;

}
.close_btn_position{
    display: flex;
    justify-content: flex-end;
}

.close_btn{
    cursor: pointer;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    position: absolute;
   
    right: 30%;
    top: 11%;
}
.edit_icon_btn{
    cursor: pointer;
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
}
.subdispo_chip{
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
   
}


.remove_subdispo{
    cursor: pointer;
    background-color: red;
    color: white;
    padding: 2.5px 5px;
    border-radius: 5px;
    border: none;
    margin: 0px 2px;
}

.delete_btn{
    cursor: pointer;
    background-color: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
}

.option_look{
    cursor: pointer;
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
}

.c_select{
    border: 1px solid #ccc;
    height: 35px;

    padding: 5px;
    
}


.select-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .select-label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .c_input_date{
    border: 1px solid #ccc;
    height: 30px;
    padding: 5px;
  }