tr {
  transition: opacity 0.5s ease-in-out; /* Apply transition for opacity */
}

.fade-out {
  opacity: 0.7; /* Set opacity to 0 to fade out */
  
}

.fade-in {
  opacity: 1; /* Set opacity to 1 to fade in */
}




