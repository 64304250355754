.datepickers-container {
    display: flex;
    align-items: center; /* Align items vertically in the center */
  }
  
  .datepicker-container {
    margin-right: 20px; /* Add some spacing between the date pickers */
  }
  
  .datepicker-label {
    margin-right: 10px; /* Add some spacing between the label and the input */
  }
  
  .datepicker-input {
    border-radius: 5px; /* Add rounded corners to the input box */
    padding: 8px; /* Add some padding for better appearance */
    border: 1px solid #ced4da; /* Add border to the input box */
  }
  
  /* Focus style */
  .datepicker-input:focus {
    border-color: #80bdff; /* Change border color on focus */
    outline: 0; /* Remove default outline */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Add box shadow on focus */
  }
  
  .invalid .datepicker-input {
    border: 1px solid red;
  }
  