.customer-vacations {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .customer-vacations h3 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .hed-class {
    font-size: 1.5rem;
    font-weight: 600;
    color: #007bff;
  }
  
  .calnde-cls {
    margin-bottom: 20px;
  }
  
  .calnde-cls-picker label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #555;
  }
  
  .calnde-cls input[type="date"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 1rem;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .calnde-cls input[type="date"]:focus {
    border-color: #007bff;
    outline: none;
  }
  

  .button-cls {
    background: #83bf91;
    color: #ffffff;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-cls:hover {
    background: #4a54ba;
    color: #ffffff;
  }
  
  .button-cls:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .vacation-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .vacation-table thead {
    background-color: #007bff;
    color: white;
  }
  
  .vacation-table thead th {
    padding: 10px;
    text-align: left;
  }
  
  .vacation-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  .vacation-table tbody tr:nth-child(even) {
    background-color: #e9ecef;
  }
  
  .vacation-table tbody td {
    padding: 10px;
    text-align: left;
  }
  
  .vacation-table tbody tr:hover {
    background-color: #d1ecf1;
  }
  
  .vacation-table td,
  .vacation-table th {
    border: 1px solid #ddd;
  }
  
  .main-panel-vaca {
    transition: width 0.25s ease, margin 0.25s ease;
    width: calc(52% - 220px);
    min-height: calc(54vh - 97px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

  @media (max-width: 600px) {
    .calnde-cls {
      margin-bottom: 15px;
    }
  
    .button-cls {
      width: 100%;
      margin-bottom: 10px;
      background: #83bf91;
      color: #ffffff;
    }
  
    .vacation-table thead {
      font-size: 0.9rem;
    }
  
    .vacation-table tbody td {
      font-size: 0.85rem;
    }
  }
  