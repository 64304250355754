.bg-gradient-info {
    background-image: linear-gradient(195deg, #49a3f1 0%, #1A73E8 100%);
}

.custom-text {
    font-size: 15px !important;
    color: #fff !important;
    font-weight: 500;
  }

.custom-green{
    background: #84bf93 !important;
}

.nav-link:hover,
  .nav-link:focus {
    background-color: #fff !important;
    color: #4751b2 !important;
    

  }

  .home-tab .nav-tabs .nav-item .nav-link {
    color: #fff;
    border-right: none;
  }

  .custom-card-text {
    font-weight:bolder;
    font-size: 24px !important;
    color: #fff !important;
  }

  .custom-active {
    background-color: #fff !important;
    color: #4751b2 !important;
}

.custom-red {
  background: linear-gradient(90deg, #ffbf96, #fe7096);
}


/* Ensure button styling is correct */
.loderes {
  position: relative;
  padding: 10px 20px;
  background-color: #28a745; /* Bootstrap's success color */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
/* Add a class when loading */
.btn.loading {
  cursor: not-allowed;
  opacity: 0.6;
}

/* Loader styling */
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
